import React, { useEffect, useState } from 'react';

import { API, Auth, Hub } from 'aws-amplify';
import { saveAs } from 'file-saver';

import logo from './logo.png';

import './App.css';

const App = () => {
  const path = window.location.pathname;

  const getUser = () => Auth.currentAuthenticatedUser().then((userData) => userData).catch(() => Auth.federatedSignIn({ customState: path }));

  const [user, setUser] = useState(null);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.error(`EVENT: ${event}`);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData.attributes));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure', data);
          break;
        case 'customOAuthState':
          console.error(data);
          window.location = decodeURIComponent(data);
          break;
        default:
          throw new Error(`Unexpected event: ${event} received from hub`);
      }
    });

    getUser().then((userData) => setUser(userData.attributes));
  }, []);

  const [body, setBody] = useState(null);
  useEffect(() => {
    if (user) {
      console.error(path);
      if (!path.match(/^\/[a-z_]+\//)) {
        setBody({ error: { msg: 'Invalid URL' } });
        return;
      }

      API.get('pdfRedirector', `/pdf${path}`)
        .then((data) => setBody(data))
        .catch((err) => setBody({ error: { msg: `SERVER ERROR: ${err}` } }));
    }
  }, [user]);

  const save = () => {
    const id = window.location.pathname.replace(/.*\//, '');
    const base64 = body.pdf;

    const data = atob(base64);
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(data.length);
    // Insert all character code into uInt8Array
    for (let i = 0; i < data.length; i += 1) {
      uInt8Array[i] = data.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: 'application/pdf' });
    saveAs(blob, `${id}.pdf`);
  };

  let msg;
  let type;
  if (!user) {
    msg = 'Authenticating...';
    type = 'dark';
  } else if (!body) {
    msg = 'Validating your access... (Be patient this can take up to 40 seconds)';
    type = 'primary';
  } else if (body.error) {
    msg = `ERROR: ${body.error.msg}`;
    type = 'danger';
  } else if (!body.allowed) {
    msg = 'You are not allowed to access this transaction';
    type = 'danger';
  }

  return (
    <div>
      <nav className="navbar navbar-dark navbar-expand-lg bg-purple purple">
        <div className="navbar-brand mx-auto d-flex align-items-center">
          <img src={logo} height="30" className="d-inline-block align-top" alt="AC3 - Clarity in the Cloud - Logo" />
          AC3 NetSuite PDF Redirector
        </div>
      </nav>

      <div className="mt-4 offset-md-2">
        <div className="mt-4 offset-md-2">
          <h1>Welcome to the AC3 NetSuite PDF Renderer</h1>
          <p>
            Plese note that PDF rendering might take some time, so be patient!
          </p>
          <p>
            If you have any feature requests or find any bugs on this site, please let us know on Slack channel <i>#ask-corp-apps-practice</i>
          </p>
        </div>
      </div>

      <div className="row justify-content-md-center">
        <div className="col-4">
          { msg && <div className={`alert alert-${type}`} role="alert">{msg}</div> }
          { !msg && (
            <div>
              <div className="alert alert-success" role="alert">Success: {body.reason}</div>
              <button type="button" className="btn btn-purple btn-large" onClick={save}>Download</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
