const oauth = {
  domain: 'ac3-netsuite-pdf-redirector-prod.auth.ap-southeast-2.amazoncognito.com',
  scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `${window.location.protocol}//${window.location.host}`, // eslint-disable-line no-undef
  redirectSignOut: `${window.location.protocol}//${window.location.host}`, // eslint-disable-line no-undef
  responseType: 'code',
  label: 'Sign in with AC3 SSO',
};

export default oauth;
